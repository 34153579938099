export default {
    computed: {
        packetList: function () {
            const packets = this.$store.state.AgriResearch.commonObj.seedPacketList.filter(item => item.status === 0)
            return packets.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        productionSeasonList: function () {
            const productionSeasonList = this.$store.state.AgriResearch.commonObj.productionSeasonList.filter(item => item.status === 0)
            return productionSeasonList.map(item => {
                        return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        seedClassList: function () {
            const classes = this.$store.state.AgriResearch.commonObj.seedClassNameList.filter(item => item.status === 0)
            return classes.map(item => {
                        return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        seedVarietyList: function () {
            const variety = this.$store.state.AgriResearch.commonObj.seedVarietyList.filter(item => item.status === 0)
            return variety.map(item => {
                        return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        hasDropdownLoadedResearch () {
            return this.$store.state.AgriResearch.commonObj.hasDropdownLoaded
        },
        seedTypeList: function () {
        return this.$store.state.AgriResearch.commonObj.seedTypeNameList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedNameList: function () {
            return this.$store.state.AgriResearch.commonObj.seedNameList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        unitList: function () {
            return this.$store.state.AgriResearch.commonObj.qualityUnitList.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        }
    },
    methods: {
        getDistrict (id) {
            if (id) {
                return this.$store.state.commonObj.districtList.find(e => e.value === parseInt(id)).text
            }
        },
        getUpazila (id) {
            if (id) {
                return this.$store.state.commonObj.upazilaList.find(e => e.value === parseInt(id)).text
            }
        },
        getUnion (id) {
            if (id) {
                return this.$store.state.commonObj.unionList.find(e => e.value === parseInt(id)).text
            }
        },
        getSeedList (typeId) {
            const seedNameList = this.$store.state.AgriResearch.commonObj.seedNameList
            if (typeId) {
                const names = seedNameList.filter(seed => seed.seed_type_id === typeId)
                return names.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
            }
            return seedNameList
        },
        getSeedName (seedId) {
            return this.$store.state.AgriResearch.commonObj.seedNameList.find(e => e.value === parseInt(seedId)).text
        },
        getSeedType (typeId) {
            return this.$store.state.AgriResearch.commonObj.seedTypeNameList.find(e => e.value === parseInt(typeId)).text
        },
        getSeedClass (classId) {
            return this.$store.state.AgriResearch.commonObj.seedClassNameList.find(e => e.value === parseInt(classId)).text
        },
        getSeedVariety (varietyId) {
            return this.$store.state.AgriResearch.commonObj.seedVarietyList.find(e => e.value === parseInt(varietyId)).text
        },
        getUnit (unitId) {
            return this.unitList.find(e => e.value === parseInt(unitId)).text
        },
        getOrg (orgId) {
            return this.orgList.find(e => e.value === parseInt(orgId)).text
        },
        getYesNo (id) {
            return this.isFinal.find(e => e.value === parseInt(id)).text
        },
        getVarietyList (typeId, nameId) {
            const list = this.$store.state.AgriResearch.commonObj.seedVarietyList
            if (typeId) {
                const names = list.filter(seed => seed.seed_type_id === typeId && seed.seed_name_id === nameId && !seed.status)
                this.seedVarietyList = names.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
            }
        }
    }
}
