<template>
  <div>
    <b-overlay :show="loadingState">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                <template>
                    <iq-card>
                        <template v-slot:headerTitle>
                            <div class="text-center">
                                <h5 class="text-dark"> {{ $t('collection.sample_collection_details') }}</h5>
                            </div>
                        </template>
                        <template v-slot:body>
                            <b-row style="">
                                <b-col md="12">
                                    <b-overlay >
                                            <b-row v-for="(detail, index) in formData.details" :key="index">
                                                <b-col md="4">
                                                    <ValidationProvider name="Sample Collection Date" :vid="'collection_date-' + index" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="collection_date"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('collection.sample_collection_date')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                class="date-picker"
                                                                id="collection_date"
                                                                v-model="detail.collection_date"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="4">
                                                    <ValidationProvider name="Collection Quantity" :vid="'quantity-' + index" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="quantity"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('collection.collection_quantity')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                id="quantity"
                                                                v-model="detail.quantity"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="4">
                                                    <ValidationProvider name="Unit of Measurement" :vid="'unit_id-' + index" rules="required|min_value:1" >
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="user_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('collection.unit_of_measurement') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                plain
                                                                id="user_id"
                                                                v-model="detail.unit_id"
                                                                :options="unitList"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="4">
                                                    <ValidationProvider name="Lot No" :vid="'lot_no-' + index" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="seed_infos_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('collection.lot_no')}} <span class="text-danger">*</span>
                                                            </template>
                                                           <b-form-select
                                                            id="lot_no"
                                                            v-model="detail.seed_infos_id"
                                                            :options="lotNumberList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="4">
                                                    <ValidationProvider name="Others File" :vid="'others_file-' + index" rules="">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            :label-for="'others_file-' + index"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('research_seed.other_file')}}
                                                            </template>
                                                            <b-form-file
                                                                :id="'others_file-' + index"
                                                                @change="onOtherFileChange($event, index)"
                                                                v-model="detail.others_file"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-file>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xl="2" lg="2" sm="2" class="mt-2">
                                                    <b-button v-if="index === formData.details.length - 1" variant=" iq-bg-success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i>{{ $t('globalTrans.add') }}</b-button>
                                                    <b-button v-if="formData.details.length > 1" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                </b-col>
                                                <b-col md="12">
                                                    <hr>
                                                    <br>
                                                </b-col>
                                            </b-row>
                                   </b-overlay>
                                </b-col>
                            </b-row>
                        </template>
                    </iq-card>
                    <iq-card>
                        <template v-slot:headerTitle>
                            <div class="text-center">
                                <h5 class="text-dark"> {{ $t('research_seed.inspection_officer_selection') }}</h5>
                            </div>
                        </template>
                        <template v-slot:body>
                            <b-row style="">
                                <b-col md="12">
                                    <b-overlay >
                                            <b-row>
                                                <b-col md="3">
                                                    <ValidationProvider name="Organization" vid="organization" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12 p-0 m-0"
                                                            label-for="organization"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('research_test.organization') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            id="organization"
                                                            @change="getUserList(),designationListt()"
                                                            v-model="formData.organization"
                                                            :options="officeList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="3">
                                                    <ValidationProvider name="Designation" vid="employee_designation" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12 p-0 m-0"
                                                            label-for="employee_designation"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.designation') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                @change="getUserList()"
                                                                id="employee_designation"
                                                                :options="designationList"
                                                                v-model="formData.employee_designation"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="3">
                                                    <ValidationProvider name="Employee" vid="user_id" rules="required|min_value:1" >
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="user_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('research_test.employee') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            id="user_id"
                                                            v-model="formData.employee_name"
                                                            :options="employeeList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="3">
                                                    <ValidationProvider name="Remarks" vid="remarks" >
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="remarks"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('research_seed.remarks') }}
                                                        </template>
                                                        <b-form-input
                                                            plain
                                                            id="remarks"
                                                            v-model="formData.remarks"
                                                        >
                                                        </b-form-input>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                   </b-overlay>
                                </b-col>
                            </b-row>
                        </template>
                    </iq-card>
                </template>
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button type="button" variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                </div>
            </b-form>
        </ValidationObserver>
    </b-overlay>
  </div>
</template>

<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { sampleCollectionSubmit, getUserList } from '../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'
import Vue from 'vue'

Vue.directive('flatpickr', {
    inserted (el) {
        flatpickr(el, {})
    }
})
export default {
    props: ['general_info_id'],
    data () {
        return {
            saveBtnName: this.$t('globalTrans.save'),
            formData: {
                employee_name: '',
                employee_designation: '',
                organization: '',
                remarks: '',
                general_info_id: null,
                details: [
                    {
                        collection_date: '',
                        unit_id: '',
                        quantity: '',
                        seed_infos_id: '',
                        others_file: null
                    }
                ]
            },
            formdata2: {},
            designationList: [],
            employeeList: [],
            info: {
                employeeList: [],
                collector: {
                    id: '',
                    employeeList: [],
                    user_id: ''
                }
            },
            attach: []
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        unitList: function () {
            const units = this.$store.state.AgriResearch.commonObj.qualityUnitList.filter(item => item.status === 0)
            return units.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        orgList: function () {
            const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
            return objectData.map(org => {
                return { value: org.value, text: this.$i18n.locale === 'en' ? org.text_en : org.text_bn }
            })
        },
        officeList: function () {
            const objectData = this.$store.state.commonObj.officeList.filter(el => el.status === 0 && el.org_id === 14)
            return objectData.map(org => {
                return { value: org.value, text: this.$i18n.locale === 'en' ? org.text_en : org.text_bn }
            })
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mixins: [researchTestingModal, researchTestingHelper],
    async created () {
        this.loading = true
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.general_info_id))
        this.formData.general_info_id = this.general_info_id
        this.formData2 = tmpData
        this.lotNumberList = this.getLotNumbers()
        if (this.authUser.office_id) {
            this.formData.organization = this.authUser.office_id
            this.designationListt()
        }
        if (this.authUser.designation_id) {
            this.formData.employee_designation = this.authUser.designation_id
        }
        this.getUserList()
        this.loading = false
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
        addItem () {
            const obj = {
                collection_date: '',
                unit_id: '',
                quantity: '',
                seed_infos_id: '',
                others_file: null
            }
            this.formData.details.push(obj)
            this.initializeFlatpickr()
            const key1 = parseInt(this.formData.details.length - 1)
            const item = this.formData.details[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.formData.details.push(obj)
            }
        },
        initializeFlatpickr () {
            this.$nextTick(() => {
                flatpickr('.date-picker', {})
            })
        },
        remove (key) {
            this.formData.details.splice(key, 1)
        },
        designationListt () {
            this.designationList = this.$store.state.commonObj.designationList.filter(e => e.office_id === this.formData.organization && e.org_id === 14)
        },
        async getUserList (orgId, desigId, item) {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            this.employeeList = []
            const params = { org_id: this.authUser.org_id, designation_id: this.formData.employee_designation }
            const result = await RestApi.getData(authServiceBaseUrl, getUserList, params)
            if (result.success) {
                result.data.map(e => {
                    const user = {
                        value: e.value, office_id: e.office_id, text: this.$i18n.locale === 'en' ? e.text_en : e.text_bn
                    }
                    if (!this.employeeList.some(existingUser => existingUser.value === user.value)) {
                        this.employeeList.push(user)
                    }
                })
                this.formData.employee_name = this.authUser.user_id
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            }
        },
        getLotNumbers () {
                return this.formData2.seed_infos.map(org => {
                    if (org.seed_id_check === 1) {
                        return { value: org.id, text: this.$i18n.locale === 'en' ? org.lot_number : org.lot_number, disabled: true }
                    } else {
                        return { value: org.id, text: this.$i18n.locale === 'en' ? org.lot_number : org.lot_number }
                    }
                })
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }
            const result = await RestApi.postData(agriResearchServiceBaseUrl, sampleCollectionSubmit, this.formData)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)
                this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: 'Success',
                    message: this.id ? 'Data updated successfully' : 'Data save successfully',
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        },
        onOtherFileChange (event, index) {
            const input = event.target
            if (input.files && input.files[0]) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.formData.details[index].others_file = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.formData.details[index].others_file = ''
            }
            }
        }
}
</script>

<style>

</style>
